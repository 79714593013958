<script setup>
import { defineProps } from "vue";
const props = defineProps({
  detail: Object,
  region: String,
});
</script>

<style scoped>
.center-title {
  text-align: center;
}
</style>

<template>
  <b-col sm="12">
    <b-card>
      <template #header>
        <div class="center-title">{{ props.region }}</div>
      </template>
      <b-card-text v-if="props.detail.money_2s != 0 || props.detail.win_2s != 0">
        <b>2 số:</b> {{ props.detail.money_2s }} | Trúng: {{ props.detail.win_2s }}
      </b-card-text>
      <b-card-text v-if="props.detail.money_3s != 0 || props.detail.win_3s != 0">
        <b>3 số:</b> {{ props.detail.money_3s }} | Trúng: {{ props.detail.win_3s }}
      </b-card-text>
      <b-card-text v-if="props.detail.money_4s != 0 || props.detail.win_4s != 0">
        <b>4 số:</b> {{ props.detail.money_4s }} | Trúng: {{ props.detail.win_4s }}
      </b-card-text>
      <b-card-text v-if="props.detail.money_dd != 0 || props.detail.win_dd != 0">
        <b>Đầu đuôi:</b> {{ props.detail.money_dd }} | Trúng: {{ props.detail.win_dd }}
      </b-card-text>
      <b-card-text v-if="props.detail.money_xc != 0 || props.detail.win_xc != 0">
        <b>Xỉu chủ:</b> {{ props.detail.money_xc }} | Trúng: {{ props.detail.win_xc }}
      </b-card-text>
      <b-card-text v-if="props.detail.money_dt != 0 || props.detail.win_dt != 0">
        <b>Đá thẳng:</b> {{ props.detail.money_dt }} | Trúng: {{ props.detail.win_dt }}
      </b-card-text>
      <b-card-text v-if="props.detail.money_dc != 0 || props.detail.win_dc != 0">
        <b>Đá chéo:</b> {{ props.detail.money_dc }} | Trúng: {{ props.detail.win_dc }}
      </b-card-text>
      <b-card-text v-if="props.detail.money_tc != 0 || props.detail.win_tc != 0"> </b-card-text>
      <b-card-text>
        <b>Tổng:</b> {{ props.detail.profit }}
        <p v-if="props.detail.profit != props.detail.commission && props.detail.commission != 0">
          ({{ props.detail.commission }})
        </p>
      </b-card-text>
    </b-card>
  </b-col>
</template>
