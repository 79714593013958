import notificationService from "@/services/notification.service";
import _ from "lodash";

export const notification = {
  namespaced: true,
  state: {
    notifications: [],
  },

  getters: {},

  actions: {
    unread({ commit, dispatch }) {
      commit("loading", true, { root: true });
      return notificationService
        .getUnread()
        .then(
          (res) => {
            commit("fetchNotifications", res.data.data);

            if (_.find(res.data.data, { unread: true })) {
              dispatch("readAll");
            }

            return Promise.resolve();
          },
          (error) => {
            console.log(error);
            return Promise.reject();
          }
        )
        .finally(() => {
          commit("loading", false, { root: true });
        });
    },
    readAll() {
      return notificationService.postReadAll().then(
        (res) => {
          console.log(res);
        },
        (error) => {
          console.log(error);
        }
      );
    },
  },
  mutations: {
    fetchNotifications(state, notifications) {
      state.notifications = notifications;
    },
  },
};
